<template>
  <div>
    <center>
      <b-overlay
        :show="busy"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
      >
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <b-spinner
              type="grow"
              variant="dark"
            />
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <!-- We add an SR only text for screen readers -->
            <span class="sr-only">Please wait...</span>
          </div>
        </template>
        <form
          id="formulario"
          :action="url"
          method="post"
        >
          <input
            v-for="(input, index) in keys"
            :key="index"
            type="hidden"
            :name="input"
            :value="values[index]"
          >
        </form>
      </b-overlay>
    </center>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      url: '',
      keys: [],
      values: [],
      busy: true,
    }
  },
  mounted() {
    const urlIncomplet = this.$route.params.urlPost
      .replaceAll('|', '.')
      .replaceAll('{', '/')
      .replaceAll('%7B', '/')
    this.url = `https://www.${urlIncomplet}`
    this.keys = this.$route.params.keys.split('|')
    this.values = this.$route.params.values.split('|')
    //
    setTimeout(() => {
      document.getElementById('formulario').submit()
    }, 3000)
    // abcpagos.com{recaudo_epa{pages{detalle.php/referencia|ingreso|login/111111|0|Ingresar
  },
}
</script>

<style></style>
